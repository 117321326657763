<template>
  <div>
    <v-snackbar v-model="showSnackBar" :color="snackbarColor">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#283e51"
      spinner="spinner"
    />
    <v-layout wrap justify-center pb-8 class="mainfont mainbg2">
      <v-flex xs12 class="mainbg2">
        <v-layout justify-center>
          <v-flex xs12 pl-4>
            <v-layout wrap justify-center>
              <v-flex xs12 pt-8 text-left>
                <span style="font-size: 25px; font-weight: bolder">
                  Profile Details</span
                >
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-4 pr-2 pr-sm-0>
              <v-flex xs12 sm6>
                <v-text-field
                  v-model="Profiledata.username"
                  label="Username *"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="usernameError"
                  @input="clearUsernameError"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 pl-0 pl-sm-2 pr-2 pr-sm-2 pt-2 pt-sm-0>
                <v-text-field
                  v-model="Profiledata.officeName"
                  label="Officename *"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :error-messages="officeNameError"
                  @input="clearOfficeNameError"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-4 pr-2 pr-sm-0>
              <v-flex xs12 sm6>
                <v-text-field
                  v-model="Profiledata.mobNumber"
                  label="Mobile Number"
                  outlined
                  dense
                  disabled
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm6 pl-0 pl-sm-2 pr-2 pr-sm-2 pt-2 pt-sm-0>
                <v-text-field
                  v-model="Profiledata.emailId"
                  label="Email"
                  outlined
                  dense
                  disabled
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-end pt-4>
              <v-flex xs12 text-end pr-2>
                <v-btn color="success" @click="validateAndSaveProfile"
                  ><span>Save</span></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    return {
      msg: null,
      showSnackBar: false,
      snackbarColor: "black", // Added for showing error/success states
      ServerError: false,
      appLoading: false,
      Profiledata: {},
      // Validation rules
      rules: {
        required: (value) => !!value || "This field is required",
      },
      // Error messages
      usernameError: "",
      officeNameError: "",
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getUserType() {
      const uType = localStorage.getItem("uType");
      if (uType === "777") {
        return "admin";
      } else {
        return "division";
      }
    },
    formatTime(time) {
      return moment(time).format("HH:mm");
    },
    goToActiveUsersPage() {
      this.$router.push({
        path: "/ActiveUsersPage",
        query: { status: "Pending", fromDashboard: true },
      });
    },

    goToLotsPage() {
      this.$router.push({
        path: "/LotsPage",
        query: { status: "Pending", fromDashboard2: true },
      });
    },
    getProfile() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.Profiledata = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          this.showErrorMessage("Failed to load profile data");
          console.log(err);
        });
    },
    validateAndSaveProfile() {
      // Clear previous error messages
      this.clearAllErrors();

      // Validate fields
      let isValid = true;

      if (
        !this.Profiledata.username ||
        this.Profiledata.username.trim() === ""
      ) {
        this.usernameError = "Username is required";
        isValid = false;
      }

      if (
        !this.Profiledata.officeName ||
        this.Profiledata.officeName.trim() === ""
      ) {
        this.officeNameError = "Office name is required";
        isValid = false;
      }

      // If validation passes, save profile
      if (isValid) {
        this.saveProfile();
      } else {
        this.showErrorMessage("Please fill in all required fields");
      }
    },
    clearUsernameError() {
      this.usernameError = "";
    },
    clearOfficeNameError() {
      this.officeNameError = "";
    },
    clearAllErrors() {
      this.usernameError = "";
      this.officeNameError = "";
    },
    showErrorMessage(message) {
      this.msg = message;
      this.snackbarColor = "error";
      this.showSnackBar = true;
    },
    showSuccessMessage(message) {
      this.msg = message;
      this.snackbarColor = "black";
      this.showSnackBar = true;
    },
    saveProfile() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/edit-user-profile",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          username: this.Profiledata.username,
          officeName: this.Profiledata.officeName,
        },
      })
        .then((response) => {
          this.showSuccessMessage(
            response.data.msg || "Profile updated successfully"
          );
          this.getProfile();
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          this.appLoading = false;
          this.showErrorMessage("Failed to update profile");
          console.log(err);
        });
    },
  },
};
</script>